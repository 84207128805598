import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { motion } from "framer-motion";
import Layout from "../components/layout/Layout";
import {
  FaEnvelope,
  FaLinkedin,
  FaGithub,
  FaMapMarkerAlt,
} from "react-icons/fa";

const ContactInfo = ({
  icon,
  title,
  content,
}: {
  icon: JSX.Element;
  title: string;
  content: string;
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "flex-start",
      mb: { xs: 2, sm: 3 },
      gap: 2,
    }}
  >
    <Box sx={{ color: "primary.main", mt: 0.5 }}>{icon}</Box>
    <Box>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          fontSize: { xs: "1rem", sm: "1.125rem" },
          mb: 0.5,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontSize: { xs: "0.875rem", sm: "1rem" },
        }}
      >
        {content}
      </Typography>
    </Box>
  </Box>
);

const Contact = () => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
    <Layout title="Get in Touch">
      <Box>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Grid
              container
              spacing={{ xs: 2, sm: 3, md: 4 }}
              alignItems="stretch"
            >
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    p: { xs: 2, sm: 3, md: 4 },
                    backgroundColor: "background.paper",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: { xs: 2, sm: 4 },
                      fontSize: { xs: "1.25rem", sm: "1.5rem" },
                    }}
                  >
                    Contact Information
                  </Typography>

                  <ContactInfo
                    icon={<FaEnvelope size={20} />}
                    title="Email"
                    content="karakuscemenes@gmail.com"
                  />

                  <ContactInfo
                    icon={<FaLinkedin size={20} />}
                    title="LinkedIn"
                    content="linkedin.com/in/karakuscemenes"
                  />

                  <ContactInfo
                    icon={<FaGithub size={20} />}
                    title="GitHub"
                    content="github.com/karakuscem"
                  />

                  <ContactInfo
                    icon={<FaMapMarkerAlt size={20} />}
                    title="Location"
                    content="Istanbul, Turkey"
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <Paper
                  component="form"
                  elevation={3}
                  sx={{
                    width: "100%",
                    p: { xs: 2, sm: 3, md: 4 },
                    backgroundColor: "background.paper",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onSubmit={handleSubmit}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: { xs: 2, sm: 4 },
                      fontSize: { xs: "1.25rem", sm: "1.5rem" },
                    }}
                  >
                    Send Message
                  </Typography>

                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    required
                    sx={{ mb: { xs: 1.5, sm: 2 } }}
                  />

                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    required
                    type="email"
                    sx={{ mb: { xs: 1.5, sm: 2 } }}
                  />

                  <TextField
                    fullWidth
                    label="Subject"
                    variant="outlined"
                    margin="normal"
                    required
                    sx={{ mb: { xs: 1.5, sm: 2 } }}
                  />

                  <TextField
                    fullWidth
                    label="Message"
                    variant="outlined"
                    margin="normal"
                    required
                    multiline
                    rows={4}
                    sx={{ mb: { xs: 2, sm: 3 } }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      mt: "auto",
                      py: { xs: 1.5, sm: 2 },
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                    }}
                  >
                    Send Message
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </Box>
    </Layout>
  );
};

export default Contact;
