import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import Layout from "../components/layout/Layout";
import { FaCode, FaServer, FaDatabase, FaTools } from "react-icons/fa";

const skillsList = [
  {
    icon: <FaCode size={40} />,
    title: "Frontend Development",
    skills: ["Angular", "TypeScript", "HTML/CSS", "ReactJS", "Flutter", "Figma"],
  },
  {
    icon: <FaServer size={40} />,
    title: "Backend Development",
    skills: ["Spring Boot", "Java", "RESTful APIs", "Microservices"],
  },
  {
    icon: <FaDatabase size={40} />,
    title: "Database",
    skills: ["PLSQL", "PostgreSQL", "MongoDB", "Redis"],
  },
  {
    icon: <FaTools size={40} />,
    title: "Tools & Others",
    skills: ["Git", "Docker", "Openshift", "Jira", "Elasticsearch"],
  },
];

const About = () => {
  const theme = useTheme();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };

  return (
    <Layout title="About Me">
      <Box>
        <Container maxWidth="lg">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <Paper
              elevation={3}
              component={motion.div}
              variants={itemVariants}
              sx={{
                p: { xs: 2, sm: 4 },
                mb: { xs: 4, sm: 8 },
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: { xs: "1rem", sm: "1.125rem" },
                  lineHeight: 1.8,
                  color: theme.palette.text.primary,
                }}
              >
                I am a Full-stack Developer at Allianz Turkey with a passion for
                building robust and scalable applications. With expertise in
                both frontend and backend technologies, I specialize in creating
                seamless user experiences while ensuring solid architectural
                foundations.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: { xs: "1rem", sm: "1.125rem" },
                  lineHeight: 1.8,
                  color: theme.palette.text.primary,
                }}
              >
                My experience includes working with modern frameworks and tools,
                implementing best practices, and delivering high-quality
                solutions that meet business requirements while maintaining code
                quality and performance.
              </Typography>
            </Paper>

            <Typography
              variant="h3"
              component={motion.h3}
              variants={itemVariants}
              sx={{
                mb: { xs: 3, sm: 6 },
                textAlign: "center",
                color: theme.palette.text.primary,
              }}
            >
              Skills & Expertise
            </Typography>

            <Grid
              container
              spacing={{ xs: 2, sm: 3, md: 4 }}
              alignItems="stretch"
            >
              {skillsList.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  sx={{ display: "flex" }}
                >
                  <motion.div variants={itemVariants} style={{ width: "100%" }}>
                    <Paper
                      elevation={3}
                      sx={{
                        height: "100%",
                        p: { xs: 2, sm: 3 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: theme.palette.background.paper,
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          height: "4px",
                          background: theme.palette.primary.main,
                        },
                        "&:hover": {
                          transform: "translateY(-4px)",
                          transition: "transform 0.2s ease-in-out",
                          "& .skill-icon": {
                            transform: "scale(1.1) rotate(5deg)",
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                    >
                      <Box
                        className="skill-icon"
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 2,
                          transition: "all 0.3s ease-in-out",
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 2,
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: { xs: "1.1rem", sm: "1.25rem" },
                          color: theme.palette.primary.main,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Box
                        sx={{
                          textAlign: "center",
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        {item.skills.map((skill, idx) => (
                          <Typography
                            key={idx}
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              fontSize: { xs: "0.875rem", sm: "1rem" },
                              "&:hover": {
                                color: theme.palette.primary.main,
                              },
                            }}
                          >
                            {skill}
                          </Typography>
                        ))}
                      </Box>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Container>
      </Box>
    </Layout>
  );
};

export default About;
