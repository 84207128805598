import { CssBaseline, GlobalStyles } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AppThemeProvider } from './theme/ThemeContext';
import AppRouter from './AppRouter';

const globalStyles = {
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },
  'html, body': {
    width: '100%',
    height: '100%',
  },
  '#root': {
    width: '100%',
    height: '100%',
  },
  '::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#94a3b8',
    borderRadius: '4px',
    '&:hover': {
      background: '#64748b',
    },
  },
};

function App() {
  return (
    <AppThemeProvider>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </AppThemeProvider>
  );
}

export default App;
