import { Box, Container, Typography, Button, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { FiGithub, FiLinkedin, FiTwitter } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";

const Hero = () => {
  const theme = useTheme();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    },
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `radial-gradient(circle at 50% 50%, ${theme.palette.primary.main}15, transparent 70%)`,
          opacity: 0.4,
          zIndex: 0,
        },
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Box sx={{ maxWidth: "1000px" }}>
            <motion.div variants={itemVariants}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  mb: 3,
                  fontSize: "16px",
                }}
              >
                Hi, my name is
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Typography
                variant="h1"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 700,
                  mb: 2,
                  fontSize: { xs: "40px", sm: "60px", md: "80px" },
                  lineHeight: 1.1,
                }}
              >
                Cem.
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Typography
                variant="h2"
                sx={{
                  color: theme.palette.text.secondary,
                  fontWeight: 700,
                  mb: 4,
                  fontSize: { xs: "35px", sm: "50px", md: "70px" },
                  lineHeight: 1.1,
                }}
              >
                I build things for the web.
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.secondary,
                  mb: 6,
                  maxWidth: "540px",
                  fontSize: "18px",
                  lineHeight: 1.7,
                }}
              >
                I'm a full-stack developer specializing in building exceptional
                digital experiences. Currently, I'm focused on building
                enterprise-level insurance applications at{" "}
                <Box
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Allianz Turkey
                </Box>
                .
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Button
                variant="outlined"
                size="large"
                component={RouterLink}
                to="/projects"
                sx={{
                  fontSize: '16px',
                  px: 4,
                  py: 2,
                  borderWidth: '2px',
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  '&:hover': {
                    borderWidth: '2px',
                    borderColor: theme.palette.primary.light,
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  },
                }}
              >
                Check out my work!
              </Button>
            </motion.div>
          </Box>
        </motion.div>

        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
          sx={{
            position: "fixed",
            left: 40,
            bottom: 0,
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            "&::after": {
              content: '""',
              width: "1px",
              height: "90px",
              backgroundColor: theme.palette.text.secondary,
            },
          }}
        >
          <motion.a
            href="https://github.com/karakuscem"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ y: -5, color: theme.palette.primary.main }}
            style={{ color: theme.palette.text.secondary }}
          >
            <FiGithub size={20} />
          </motion.a>
          <motion.a
            href="https://linkedin.com/in/karakuscemenes"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ y: -5, color: theme.palette.primary.main }}
            style={{ color: theme.palette.text.secondary }}
          >
            <FiLinkedin size={20} />
          </motion.a>
        </Box>

        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
          sx={{
            position: "fixed",
            right: 40,
            bottom: 0,
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            "&::after": {
              content: '""',
              width: "1px",
              height: "90px",
              backgroundColor: theme.palette.text.secondary,
            },
          }}
        >
          <motion.a
            href="mailto:karakuscemenes@gmail.com"
            whileHover={{ y: -5, color: theme.palette.primary.main }}
            style={{
              color: theme.palette.text.secondary,
              textDecoration: "none",
              writingMode: "vertical-rl",
              fontSize: "14px",
              fontFamily: theme.typography.fontFamily,
              letterSpacing: "0.1em",
            }}
          >
            karakuscemenes@gmail.com
          </motion.a>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
