import Layout from '../components/layout/Layout';
import Hero from '../components/sections/Hero';

const Home = () => {
  return (
    <Layout>
      <Hero />
    </Layout>
  );
};

export default Home; 