import {
  Box,
  Container,
  Typography,
  Paper,
  Chip,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import Layout from "../components/layout/Layout";

interface Experience {
  company: string;
  position: string;
  period: string;
  description: string[];
  technologies: string[];
}

const experiences: Experience[] = [
  {
    company: "Allianz Turkey",
    position: "Full-stack Developer",
    period: "2023 - Present",
    description: [
      "Developed and maintained insurance applications using Angular and Spring Boot",
      "Implemented RESTful APIs and microservices architecture",
      "Collaborated with cross-functional teams to deliver high-quality solutions",
      "Optimized application performance and improved user experience",
    ],
    technologies: [
      "Angular",
      "TypeScript",
      "Spring Boot",
      "Java",
      "PLSQL",
      "Docker",
      "Kubernetes",
    ],
  },
  {
    company: "Allianz Turkey",
    position: "Full-stack Developer Intern",
    period: "2023 - 2023",
    description: [
      "Assisted in developing and testing insurance applications",
      "Learned and implemented Angular and Spring Boot best practices",
      "Participated in agile development processes and daily stand-ups",
      "Contributed to the development of RESTful APIs and frontend components",
      "Gained hands-on experience with enterprise-level software development",
    ],
    technologies: [
      "Angular",
      "TypeScript",
      "Spring Boot",
      "Java",
      "PLSQL",
      "Git",
      "Agile",
    ],
  },
];

const Experience = () => {
  const theme = useTheme();

  return (
    <Layout title="Professional Experience">
      <Box>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 3, sm: 4 },
              }}
            >
              {experiences.map((exp, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      p: { xs: 2, sm: 3, md: 4 },
                      backgroundColor: "customBackground.card",
                      borderLeft: "4px solid",
                      borderColor: "primary.main",
                      transition:
                        "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "primary.main",
                          fontWeight: 600,
                          mb: 1,
                        }}
                      >
                        {exp.position}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "text.primary",
                          mb: 1,
                        }}
                      >
                        {exp.company}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "text.secondary",
                          fontWeight: 500,
                        }}
                      >
                        {exp.period}
                      </Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      {exp.description.map((desc, idx) => (
                        <Typography
                          key={idx}
                          variant="body1"
                          sx={{
                            color: "text.primary",
                            mb: 1,
                            position: "relative",
                            pl: 3,
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              left: 0,
                              top: "0.7em",
                              width: 6,
                              height: 6,
                              backgroundColor: "primary.main",
                              borderRadius: "50%",
                            },
                          }}
                        >
                          {desc}
                        </Typography>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        "& > *": { margin: "2px" },
                      }}
                    >
                      {exp.technologies.map((tech, idx) => (
                        <Chip
                          key={idx}
                          label={tech}
                          sx={{
                            backgroundColor: "customBackground.tag",
                            color: "text.primary",
                            fontWeight: 500,
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color:
                                theme.palette.mode === "light"
                                  ? "white"
                                  : "black",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Paper>
                </motion.div>
              ))}
            </Box>
          </motion.div>
        </Container>
      </Box>
    </Layout>
  );
};

export default Experience;
