import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  useScrollTrigger,
  Container,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiMenu, FiX } from "react-icons/fi";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useThemeContext } from "../../theme/ThemeContext";

const navItems = [
  { name: "About", path: "/about", number: "01" },
  { name: "Experience", path: "/experience", number: "02" },
  { name: "Projects", path: "/projects", number: "03" },
  { name: "Contact", path: "/contact", number: "04" },
];

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const { mode, toggleColorMode } = useThemeContext();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={trigger ? 1 : 0}
        sx={{
          py: 1,
          background: trigger
            ? `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`
            : "transparent",
          backdropFilter: "blur(8px)",
          borderBottom: trigger ? `1px solid ${theme.palette.divider}` : "none",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: "none",
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontFamily: "'Fira Code', monospace",
                fontSize: "1.5rem",
                fontWeight: 700,
                letterSpacing: "-0.5px",
              }}
            >
              {"<CK />"}
            </Typography>

            {isMobile ? (
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <IconButton
                  onClick={toggleColorMode}
                  color="inherit"
                  aria-label="toggle theme"
                  sx={{
                    color: "text.primary",
                    bgcolor: "background.paper",
                    boxShadow: 1,
                    "&:hover": {
                      bgcolor: "background.default",
                    },
                  }}
                >
                  {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 1,
                    "&:hover": {
                      bgcolor: "background.default",
                    },
                  }}
                >
                  {mobileOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                {navItems.map((item) => (
                  <Button
                    key={item.path}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      color: "text.secondary",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "14px",
                      fontWeight: 500,
                      letterSpacing: "0.2px",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        width: "0%",
                        height: "2px",
                        bottom: -4,
                        left: "50%",
                        transform: "translateX(-50%)",
                        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        transition: "width 0.3s ease-in-out",
                      },
                      "&:hover": {
                        color: "text.primary",
                        backgroundColor: "transparent",
                        "&::after": {
                          width: "100%",
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: theme.palette.primary.main,
                        mr: 1,
                        fontFamily: "'Fira Code', monospace",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {item.number}.
                    </Typography>
                    {item.name}
                  </Button>
                ))}
                <Button
                  variant="outlined"
                  component="a"
                  href="/assets/resume.pdf"
                  download="Cem Enes Karakus - Resume.pdf"
                  sx={{
                    ml: 2,
                    fontFamily: "'Inter', sans-serif",
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    borderWidth: '2px',
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    '&:hover': {
                      borderWidth: '2px',
                      borderColor: theme.palette.primary.light,
                      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    },
                  }}
                >
                  Resume
                </Button>
                <IconButton
                  onClick={toggleColorMode}
                  color="inherit"
                  aria-label="toggle theme"
                  sx={{
                    color: "text.primary",
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <AnimatePresence>
        {isMobile && mobileOpen && (
          <Box
            component={motion.div}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", damping: 20, stiffness: 100 }}
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              width: "75%",
              backgroundColor: "background.paper",
              backdropFilter: "blur(10px)",
              borderLeft: `1px solid ${theme.palette.divider}`,
              zIndex: theme.zIndex.appBar - 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
              p: 4,
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item.path}
                component={RouterLink}
                to={item.path}
                onClick={handleDrawerToggle}
                sx={{
                  color: "text.primary",
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "16px",
                  fontWeight: 500,
                  width: "100%",
                  justifyContent: "flex-start",
                  py: 1.5,
                  px: 2,
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: "action.hover",
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                    mr: 2,
                    fontFamily: "'Fira Code', monospace",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {item.number}.
                </Typography>
                {item.name}
              </Button>
            ))}
            <Button
              variant="outlined"
              component="a"
              href="/assets/resume.pdf"
              download="Cem Enes Karakus - Resume.pdf"
              sx={{
                mt: 2,
                width: '100%',
                fontFamily: "'Inter', sans-serif",
                fontSize: '16px',
                fontWeight: 500,
                borderWidth: '2px',
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                '&:hover': {
                  borderWidth: '2px',
                  borderColor: theme.palette.primary.light,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                },
              }}
            >
              Resume
            </Button>
          </Box>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
